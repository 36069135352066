/* eslint-disable */
export default {
  pages: {
    key: "title",
    data: [
      {title: 'Dashboard',   url: '/',      icon: 'BarChart2Icon', is_bookmarked: false},
      {title: 'Administradores', url: '/administradores', icon: 'UserIcon', is_bookmarked: false},
    ]
  }
}
/* eslint-enable */
