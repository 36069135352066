/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import store from "@/store/store";

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [

        {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
            path: '',
            component: () => import('./layouts/main/Main.vue'),
            meta: { authRequired: true },
            children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
              {
                path: '/',
                name: 'Dashboard',
                meta: { authRequired: true },
                component: () => import('./views/Dashboard.vue'),
              },
              {
                path: '/administradores/:id?',
                name: 'Administradores',
                meta: { authRequired: true },
                component: () => import('./views/Admins.vue'),
              },
              {
                path: '/projetos/:id?',
                name: 'Projetos',
                meta: { authRequired: true },
                component: () => import('./views/Projects.vue'),
              },
              {
                path: '/sentry/:id?',
                name: 'Sentry',
                meta: { authRequired: true },
                component: () => import('./views/SentryBindings.vue'),
              },
            ],
        },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
        {
            path: '',
            component: () => import('@/layouts/full-page/FullPage.vue'),
            children: [
        // =============================================================================
        // PAGES
        // =============================================================================
              {
                path: '/pages/login',
                name: 'page-login',
                component: () => import('@/views/pages/Login.vue')
              },
              {
                path: '/pages/error-404',
                name: 'page-error-404',
                component: () => import('@/views/pages/Error404.vue')
              },
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/pages/error-404'
        }
    ],
})

router.beforeEach((to, from, next) => {
  if (to.meta.authRequired && !(store.state as any).auth.isUserLoggedIn()) {
    router.push({ path: "/pages/login", query: { to: to.path } });
  }
  return next();
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})

export default router
