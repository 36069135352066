import { Module } from "vuex";
import axios from "../../axios";
import router from "../../router";
import Vue from "@/main";

const authModule: Module<any, any> = {
  namespaced: true,
  state: {
    isUserLoggedIn() {
      return !!localStorage.getItem("accessToken") && !!localStorage.getItem("userInfo");
    },
    getBearerToken() {
      return localStorage.getItem("accessToken");
    },
  },
  mutations: {
    SET_BEARER(state: any, token: string) {
      localStorage.setItem("accessToken", token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  },
  actions: {
    async login({ commit }, payload) {
      try {
        const loginResponse = await axios.post("auth/login", payload);
        const session = loginResponse.data;
        if (session.user.role !== "ADMINISTRATOR") {
          Vue.$vs.notify({
            title: "Acesso negado",
            text: "Você não possui as permissões necessárias para este acesso.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
          return;
        }
        commit("SET_BEARER", session.token); // set token in axios
        commit("UPDATE_USER_INFO", session.user, { root: true });
        router.push((router.currentRoute.query.to || "/") as string);
        return session;
      } catch (error) {
        console.error(error);
        if (error.response?.status === 400 || error.response?.status === 404) {
          throw Error("Email ou senha incorretos");
        }
        throw Error("Houve um erro na conexão com o servidor");
      }
    },
    async logout({ commit }) {
      commit("SET_BEARER", "");
      commit("UPDATE_USER_INFO", {}, { root: true });
      router.push("/pages/login");
    },
    async loadTokenFromStorage({ commit, state }) {
      if (state.isUserLoggedIn()) {
        const token = state.getBearerToken();
        commit("SET_BEARER", token);
      }
    },
  },
}

export default authModule;
